<template>
  <excel-xlsx
    @click="SET_HANDLE_CLICK"
    :data="items"
    id="excel-form"
    :columns="columns"
    :file-name="filename"
    :file-type="'xls'"
    :sheet-name="sheetname"
  >
    <i class="mr-2 material-icons-outlined">file_download</i> Export to Excel
    ({{ count }} item{{ count > 1 ? "s" : "" }})
  </excel-xlsx>
</template>

<script>
import excelXlsx from "./ExportExcell2.vue";
export default {
  components: {
    excelXlsx,
  },
  data() {
    return {
      sheetname: "Report List",
    };
  },
  props: {
    count: {
      default: 0,
    },
    filename: {
      default: "Service-Reports",
    },
    items: {
      default: () => [],
    },
    columns: {
      default: () => [],
    },
  },
  methods: {
    SET_HANDLE_CLICK() {
      alert("clicked");
    },
  },
};
</script>

<style>
</style>